/*------------------------------------
    Google Font  
------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,700,800,900|Poppins:300,400,500,600,700,700i&display=swap");

/*------------------------------------
    Typography
------------------------------------*/

body {
  font-size: 14px;
  letter-spacing: 0.75px;
  font-family: "Montserrat", sans-serif;
}
p {
  font-weight: 500;
  color: $text-color;
  line-height: 1.8;
}
a {
  transition: all 0.4s ease-in-out 0s;
}
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
  outline: none;
}
a:visited {
  color: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd,
ol,
ul,
hr,
pre,
menu,
table,
caption,
address,
blockquote {
  margin: 0;
  margin-bottom: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $heading-color;
  font-family: $heading-font;
}
h1,
.h1 {
  line-height: 1.2;
  font-size: $h1;
}
h2,
.h2 {
  font-size: $h2;
}
h3,
.h3 {
  font-size: $h3;
}
h4,
.h4 {
  font-size: $h4;
}
h5,
.h5 {
  font-size: $h5;
}
h6,
.h6 {
  font-size: $h6;
}
h1,
.h1 {
  font-weight: 800;
}
h2,
.h2,
h3,
.h3 {
  font-weight: 600;
}
h4,
.h4,
h5,
.h5 {
  font-weight: 600;
}
h6,
.h6 {
  font-weight: 500;
}

::-webkit-input-placeholder {
  color: $base-1;
}
:-ms-input-placeholder {
  color: $base-1;
}
::placeholder {
  color: $base-1;
}
