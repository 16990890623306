/*------------------------------------
    Spacing
------------------------------------*/

.mb-0 {
    margin-bottom: 0;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb--15 {
    margin-bottom: -15px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb--30 {
    margin-bottom: -30px;
}
.mb--45 {
    margin-bottom: -45px;
}
.pl-0 {
    padding-left: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.pt-15 {
    padding-top: 15px;
}
.pt-30 {
    padding-top: 30px;
}
.pl-15 {
    padding-left: 15px;
}
.pl-30 {
    padding-left: 30px;
}

.mt-spacing {
    margin-top: $space;
}
.mb-spacing {
    margin-bottom: $space;
}