/*------------------------------------
    Element: Forms
------------------------------------*/

/*------------------------------------
Forms
------------------------------------*/
.form select,
.form textarea,
.form input[type="tel"],
.form input[type="text"],
.form input[type="date"],
.form input[type="email"],
.form input[type="number"],
.form input[type="search"],
.form input[type="password"] {
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 1.5;
}
.form textarea,
.form select[multiple] {
  min-height: 180px;
}
/* 
* Form - Style 1
*/
.form-1 .row {
  margin-left: -12px;
  margin-right: -12px;
}
.form-1 .row > * {
  padding-left: 12px;
  padding-right: 12px;
}
.form-1 label {
  top: -7px;
  left: 10px;
  color: $base-1;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.02em;
  display: inline-block;
  position: absolute;
  margin-bottom: 9px;
  padding: 0 6px;
  background-color: $base-0;
  transition: all 0.2s;
}
.form-1 select,
.form-1 textarea,
.form-1 input[type="tel"],
.form-1 input[type="text"],
.form-1 input[type="date"],
.form-1 input[type="email"],
.form-1 input[type="number"],
.form-1 input[type="search"],
.form-1 input[type="password"] {
  width: 100%;
  height: 45px;
  color: $base-1;
  display: block;
  font-size: 12px;
  padding: 10px 10px 0;
  outline: 0;
  border: 1px solid $base-1;
  background-color: $base-0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-1 textarea {
  padding-top: 15px;
}
.form-1 .form-item {
  position: relative;
  margin-bottom: 24px;
}
.form-1 input:focus,
.form-1 select:focus,
.form-1 textarea:focus {
  border-color: $primary;
}

.social-links {
  text-align: left;
}

.social-links a {
  font-size: 34px;
  display: inline-block;
  background: $primary;
  color: #fff;
  line-height: 0;
  padding: 16px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 65px;
  height: 65px;
  transition: 0.3s;
  margin: 0 5px 0 5px;
}

.social-links a:hover {
  background: #fff;
  color: $primary;
  border: 1px solid $primary;
}
