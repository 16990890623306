/*------------------------------------
    Section: Service
------------------------------------*/

.section-service {
    .service-wrap {
        position: relative;
        padding-top: 100%;

        .service-item {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            padding: 30px;
            border: 1px solid #000;

            p {
                font-size: 12px;
            }
            h3 {
                display: block;
                font-size: 14px;
                font-weight: 700;
                padding-top: 55px;
                margin-bottom: 20px;
            }
            .el-icon-icon {
                top: 30px;
                left: 30px;
                width: 36px;
                height: 36px;
                font-size: 30px;
                position: absolute;
                text-align: center;
            }
            &:hover {
                background-color: $primary;
            }
        }
    }
}

// Service 3
.section-service-3 {
    .service-wrap {
        .service-item {
            background-color: $primary;
        }
    }
}

@media (max-width: 768px) {
    .section-service {
        .service-wrap {
            .service-item {
                padding: 10px 20px 0;

                h3 {
                    margin-bottom: 10px;
                }
                .el-icon-icon {
                    top: 15px;
                    left: 15px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .section-service {
        .service-wrap {
            padding-top: 220px;
            .service-item {
                padding: 30px 30px 0;

                h3 {
                    margin-bottom: 10px;
                }
                .el-icon-icon {
                    top: 30px;
                    left: 30px;
                }
            }
        }
    }
}
